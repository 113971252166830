
const Paralax = (text, divId) => {

    const divClass = "bgimg-" + divId + " w3-display-container w3-opacity-min"
    return (
        <div className={divClass} id="home" style={{height: '100%', width: '100%'}}>
            <div className="w3-display-middle" style={{whiteSpace: "nowrap"}}>
               {text}
            </div>
        </div>
    )
}

export default Paralax
