

const Releases = ({data2}) => {

    return (data2.map((release) => { return (
            <tr>
                <td align="center">{release.tag_name}</td>
                <td align="center">{release.name}</td>
                <td align="center"><a href={window.URL.createObjectURL(new Blob([release.body]))}>Changelog</a></td>
                <td align="center"><a className="w3-button w3-gray" style={{color: "white", textDecoration: "none"}} href={release.assets[0].browser_download_url}>Download</a></td>
            </tr>
        )
    })
    )
}

export default Releases