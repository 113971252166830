//https://api.github.com/repos/agentdid127/ResourcePackConverter/releases
//
import useFetch from "../Util/useFetch";
import Stats from "./Stats";

const StatItem = ({location, title}) => {
    const {data, isPending, error} = useFetch(location)

    const title2 = <div>{title}</div>
    return (
        <div>
            {error && <div>{ error }</div>}
                {isPending && <div className="w3-third w3-section">Loading...</div>}
                {data && console.log(data)}
                {data &&
            <div className="w3-third w3-section">
                <span className="w3-xlarge"><Stats data={data} /></span><br />
                 {title}
            </div>}
        </div>
    )
} 

export default StatItem