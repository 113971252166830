import { useHistory } from "react-router"
import Paralax from "./Util/Paralax";

const NotFound = () => {

    const history = useHistory();

    const text = <span className="w3-center w3-content w3-padding-large w3-black w3-xlarge w3-wide w3-animate-opacity"><span className="w3-hide-small">Sorry!</span></span>
    const func =  Paralax(text, 1)

    return (
        <div>
            {func}
            <br />
            <br />
            <h1 className="w3-center">404: Not Found</h1>
            <h6 className="w3-center">The page could not be found.</h6>
            <p className="w3-center">Redirecting you to the homepage in a few seconds...</p>
            {
                setTimeout(() => {
                    history.push("/")
                }, 1000 * 5)
            }
        </div>
    )


}

export default NotFound