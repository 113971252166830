
const Footer = () => {
    return (
    <footer className="w3-center w3-black w3-padding-64 w3-opacity w3-hover-opacity-off">
        <a href="#home" className="w3-button w3-light-grey"><i className="fa fa-arrow-up w3-margin-right"></i>To the top</a>
        <div className="w3-xlarge w3-section" style={{textDecoration: "none"}}>

            <a href="https://www.instagram.com/agentdid127/" style={{textDecoration: "none"}}><i className="fa fa-instagram w3-hover-opacity"></i> </a>
            <a href="https://www.youtube.com/channel/UCMCqGnqykw5rcdjrG-tg19w" style={{textDecoration: "none"}}><i className="fa fa-youtube-play w3-hover-opacity"></i> </a>
            <a href="https://twitter.com/agentdid127" style={{textDecoration: "none"}}><i className="fa fa-twitter w3-hover-opacity"></i> </a>

        </div>
        <p>&copy; {new Date().getFullYear().toString()} agentdid127</p>
        <p>Original Website used a template from <a href="https://www.w3schools.com/w3css/w3css_templates.asp">w3schools.com</a></p>
        <p>Site made with React.JS</p>
    </footer>
    )
}

export  default Footer
