import Nav from './Navigation/Nav';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
//import HomePage from './CoryBorek/HomePage';
import HomePage from './Home/HomePage';
import Footer from './Util/Footer';
import ResourcePackConverter from './Projects/RPC/Individual/ResourcePackConverter';
import NotFound from './NotFound';
import Projects from './Projects/Projects';
import RPCGUI from './Projects/RPC/Individual/RPCGUI';
import RPC from './Projects/RPC/RPC';
import RPCBedrock from './Projects/RPC/RPCBedrock';

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <div className="content">
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/projects">
              <Projects />
              
            </Route>
            <Route path="/ResourcePackConverter">
              <ResourcePackConverter />
            
            </Route>
            <Route path="/RPC-Bedrock">
              <RPCBedrock />
            </Route>
            <Route path="/RPCGUI">
              <RPCGUI />
            </Route>
            <Route path="/RPC">
              <RPC />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
