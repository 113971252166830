import ProjectTitle from "./ProjectTitle"

const Projects = () => {

    return (
        <div>
        <ProjectTitle />
        <div>
        <a style={{textDecoration: 'none'}} href="/RPC">
        <h4 className="w3-center">Resource Pack Converter</h4>
        <p className="w3-center">My fork of Hypixel's Resource Pack Converter that I've been updating since 2019. Though things are slow at some times, I still try to keep up with the latest update of the game.</p>
        </a>
        <a style={{textDecoration: 'none'}} href="/RPC-Bedrock">
        <h4 className="w3-center">RPC-Bedrock</h4>
        <p className="w3-center">A Program that converts Minecraft: Java Edition Resource Packs to Bedrock Edition</p>
        </a>

        </div>
        </div>
    )
}

export default Projects
