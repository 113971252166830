import Home from "../Home"
import Releases from "../Releases"
import useFetch from "../../Util/useFetch"



const RPC = () => {

    const {data, isPending, error} = useFetch('https://api.github.com/repos/agentdid127/RPC-Updater/releases')
    return (
        <div>
            {Home("Resource Pack Converter")}
            <h2 className="w3-center">Download</h2>
            <div className="w3-content w3-container w3-padding-64" id="about">
                <h3 className="w3-center">Downloads</h3>
                <div className="w3-row">
                {error && <div>{ error }</div>}
                {isPending && <div>Loading...</div>}
                {data && console.log(data)}
                {data &&
                    
                    
                    <table id="stuff" width="75%" align="center">
                        <tbody>
                        <tr>
                            <th>Release Tag</th>
                            <th>Release Title</th>
                            <th>Changelog</th>
                            <th>Download</th>
                        </tr>
                        
                        <Releases data2={data} />
                        </tbody>
                    </table>}
                    

                </div>
            </div>
            <h6 className="w3-center"><b>Or alternitavely download an individual version</b></h6>
            <a href="./ResourcePackConverter" style={{textDecoration: "none"}}>
                <div className="w3-content w3-container w3-padding-64" id="about">
                    <h3 className="w3-center">Console</h3>
                    <p>
                        The original version of the converter, which is a heavily modified fork of Hypixel's converter.
                        Works as a console application, and is good for automatic conversions, and CI stuff (or if you really like running commands)
                    </p>
                </div>
            </a>
            <a href="./RPCGUI" style={{textDecoration: "none"}}>
                <div className="w3-content w3-container w3-padding-64" id="about">
                    <h3 className="w3-center">GUI</h3>
                    <p>
                        The newest version of the converter, runs exactly the same as the original, but with an added GUI for easy use to beginners, Just Java necessary!
                    </p>
                </div>
            </a>
        </div>
    );
}

export default RPC;