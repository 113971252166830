import Home from "../Home"
import Releases from "../Releases"
import useFetch from "../../Util/useFetch"

const RPCBedrock = () => {
    const {data, isPending, error} = useFetch('https://api.github.com/repos/agentdid127/RPC-Bedrock/releases')

    return (
        <div>
            {Home("RPC-Bedrock")}
            <h2 className="w3-center">Download</h2>
            <div className="w3-content w3-container w3-padding-64" id="about">
                <h3 className="w3-center">Downloads</h3>
                <div className="w3-row">
                {error && <div>{ error }</div>}
                {isPending && <div>Loading...</div>}
                {data && console.log(data)}
                {data &&
                    
                    
                    <table id="stuff" width="75%" align="center">
                        <tbody>
                        <tr>
                            <th>Release Tag</th>
                            <th>Release Title</th>
                            <th>Changelog</th>
                            <th>Download</th>
                        </tr>
                        
                        <Releases data2={data} />
                        </tbody>
                    </table>}
                    

                </div>
            </div>
        </div>
    )
}

export default RPCBedrock