import Paralax from '../Util/Paralax';


const Home = () => {
    const text = <span className="w3-center w3-content w3-padding-large w3-black w3-xlarge w3-wide w3-animate-opacity"><span className="w3-hide-small">agentdid127</span>'s Site</span>
    const func =  Paralax(text, 1)

    return (
        <div>{func}</div>
    )
}





export default Home;
