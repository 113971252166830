
const Nav = () => {

    const toggleFunction = (e) => {
        
        var x = document.getElementById("navDemo");
        if (x.className.indexOf("w3-show") === -1) {
            x.className += " w3-show";
            e.preventDefault();
        } else {
            x.className = x.className.replace(" w3-show", "");
        }
    }
    return (
        <div className="w3-top">
            <div className="w3-barw w3-card w-animate-top w3-white" id="myNavbar">
                <a className="w3-bar-item w3-button w3-hover-black w3-hide-medium w3-hide-large w3-right" href="/" onClick={toggleFunction} title="Toggle Navigation Menu">
                    <i className="fa fa-bars"></i>
                </a>
                <a href="/#home" className="w3-bar-item w3-button">HOME</a>
                <a href="/#about" className="w3-bar-item w3-button w3-hide-small"><i className="fa fa-user"></i> ABOUT</a>
                { /*a href="/#contact" className="w3-bar-item w3-button w3-hide-small"><i className="fa fa-envelope"></i> CONTACT</a> */ }
                <a href="/projects" className="w3-bar-item w3-button w3-right w3-hide-small"><i className="fa fa-folder-open"></i> PROJECTS</a>
            </div>

        
            <div id="navDemo" className="w3-bar-block w3-white w3-hide w3-hide-large w3-hide-medium">
                <a href="/#about" className="w3-bar-item w3-button" onClick={toggleFunction}>ABOUT</a>
                
                { /* <a href="/#contact" className="w3-bar-item w3-button" onClick={toggleFunction}>CONTACT</a> */}
                <a href="/projects" className="w3-bar-item w3-button">PROJECTS</a>
            </div>
        </div>
    );
}

export default Nav;