

const Stats = ({data}) => {
var downloads = 0;

(data.map(release => {
    release.assets.map(asset => {
        downloads += asset.download_count
    })
}))

return downloads;
}

export default Stats