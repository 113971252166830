
import useFetch from "../Util/useFetch";
import StatItem from "./StatItem";


const Statistics = () => {

    return (
        <div className="w3-row w3-center w3-dark-grey w3-padding-16">
            

            <StatItem location='https://api.github.com/repos/agentdid127/ResourcePackConverter/releases' title={<div>Resource Pack Converter (CONSOLE) Downloads</div>} />
            <StatItem location='https://api.github.com/repos/agentdid127/RPC-GUI/releases'  title={<div>Resource Pack Converter (GUI) Downloads</div>} />
            <StatItem location='https://api.github.com/repos/agentdid127/RPC-Bedrock/releases' title={<div>RPC-Bedrock Downloads</div>} />
            
        </div>
    )
}

export default Statistics;