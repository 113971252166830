
const About = () => {

    return (
        <div className="w3-content w3-container w3-padding-64" id="about">
        <h3 className="w3-center">ABOUT AGENTDID127</h3>
        <p className="w3-center"><em>Open-Source Programming</em></p>
        <p>We are the developers of multiple projects, including a fork of Hypixel's Resource Pack Converter, and other utilities</p>
            <div className="w3-row">
                <div className="w3-col m6 w3-center w3-padding-large">
                    <p><b>agentdid127 logo</b></p><br/>
                    <img src="logo.png" className="w3-round w3-image w3-opacity w3-hover-opacity-off" alt="Me" width="500" />
                </div>

                <div className="w3-col m6 w3-hide-small w3-padding-large">
                    <h5>Projects</h5><br />
                    <p>
                    Resource Pack Converter: <a href="RPC/">https://www.agentdid127.com/RPC/</a><br />
                    RPC-Bedrock: <a href="RPC-Bedrock/">https://www.agentdid127.com/RPC-Bedrock/</a><br />
                    </p>
                
                
                </div>
            </div>
        </div>
    )
}

export default About;
