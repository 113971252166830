import Paralax from "../Util/Paralax"


const ProjectTitle = () => {
    const text = <span className="w3-center w3-padding-large w3-black w3-xlarge w3-wide w3-animate-opacity">Projects</span>

    return Paralax(text, 1)
}

export default ProjectTitle
