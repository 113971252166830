import Footer from "../Util/Footer";
import Paralax from "../Util/Paralax";
import Home from "./Home"
import About from "./About"
import Statistics from "./Statistics";

const HomePage = () => {
    return (
        <div>
        <Home />
        <About />
        <Statistics />
        </div>
    )
}

export default HomePage